import styles from './LoadingScreen.module.css'

const LoadingScreen = () => {

    return (
        <div className={styles.loading_screen}>
            <img src='/hi-final.png'/>

        </div>
    )

}

export default LoadingScreen;