import logo from './logo.svg';
import {useEffect, useState} from "react";
import './App.css';
import NavBar from './Components/navBar/NavBar.jsx'
import Splash from './Components/splash/Splash.jsx'
import WhoWeAre from './Components/whoWeAre/WhoWeAre.jsx';
import Services from './Components/services/Services.jsx';
import ContactUs from './Components/contactUs/ContactUs.jsx';
import Footer from './Components/footer/Footer.jsx';
// import Loading from './Components/loading/Loading.jsx'
import LoadingScreen from "./Components/loading/LoadingScreen";



function App() {

    return (
        <div className="App">
            <LoadingScreen/>
            <NavBar/>
            <div id="home"><Splash/></div>
            <div id='about'><WhoWeAre/></div>
            <div id='services'><Services/></div>
            <div id='contact'><ContactUs/></div>
            <Footer/>
        </div>
    );
};

export default App;
