import styles from './Splash.module.css';

const Splash = () => {

    return (
        <div className={styles.splash_container}>
            {/* left side, or top in mobile */}
            <div className={styles.splash_left}>
                {/* div below is the shading for the picture */}
                <div className={styles.filter}
                ></div>
                {/*<h1>TRIUMPH REALTY LLC</h1>*/}
                {/*<img src='./assets/logo-full-white.png'/>*/}
                <img src='./assets/logo-full-transparent.png' alt='Triumph Realty Logo'/>
                <h2>NYC Real Estate Brokerage</h2>
            </div>

            {/* right side, or bottom in mobile */}
            <div className={styles.splash_right}>
                {/* div below is how we get the tilt border */}
                <div className={styles.skew}></div>
                <div className={styles.right_content}>
                    <div className={styles.right_content_title}><h2>Our Specialties</h2></div>
                    <div className={styles.splash_right_text_container}>
                        {/*<p className={styles.splash_right_p1}>Looking for large or small, condo or family home,*/}
                        {/*    fixer-uppers, or luxury? We will find*/}
                        {/*    it and get it for you at the price that's right. We can also help with staging, flipping,*/}
                        {/*    building, and more.</p>*/}
                        {/*<p className={styles.splash_right_p2}>If you have questions about credit or legal matters,*/}
                        {/*    buying or selling, you can trust us*/}
                        {/*    to*/}
                        {/*    find*/}
                        {/*    you what you need fast. We'll make sure you feel confident and educated every step of*/}
                        {/*    the*/}
                        {/*    way.*/}
                        {/*</p>*/}
                        {/*<p className={styles.splash_right_p3}>You probably have questions. When is the best time to buy*/}
                        {/*    or sell? How much house can I*/}
                        {/*    afford?*/}
                        {/*    How much is my house worth? It might seem intimidating, but our experienced group is*/}
                        {/*    here to*/}
                        {/*    help!</p>*/}
                        {/*<p className={styles.splash_right_p4}>Looking for large or small, condo or family home,*/}
                        {/*    fixer-uppers, or luxury? We will find*/}
                        {/*    it and get it for you at the price that's right. We can also help with staging, flipping,*/}
                        {/*    building, and more.</p>*/}
                        {/*<p className={styles.splash_right_p1}>Our Specialties</p>*/}
                        <p className={styles.splash_right_p2}>Commercial Real Estate
                        </p>
                        <p className={styles.splash_right_p3}>Office Retail and Hospitality</p>
                        <p className={styles.splash_right_p4}>Leases, Sales, and Consulting</p>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Splash;