import styles from './Services.module.css'

const Services = () => {

    return (
        <div className={styles.page}>
            <div className={styles.services_container}>
                <h1 className={styles.services_header}>Our Services</h1>
                <ul className={styles.services_ul}>
                    <li className={styles.services_li}>Market Analysis, Expertise, and Guidance</li>
                    <li className={styles.services_li}>Strategic Marketing and Lead Generation</li>
                    <li className={styles.services_li}>Expert Negotiation Tactics
                    </li>
                    <li className={styles.services_li}>Seamless Transaction Management</li>
                    <li className={styles.services_li}>Client-Centric Approach</li>
                    <li className={styles.services_li}>Technology-Powered Solutions
                    </li>
                    <li className={styles.services_li}>Streamlined Home Search
                    </li>
                    <li className={styles.services_li}>Expert Seller Guidance</li>
                    <li className={styles.services_li}>Personally Tailored Solutions</li>

                </ul>
            </div>
        </div>
    )
}

export default Services;