
import styles from './ContactForm.module.css'

const ContactForm = () => {

    //   contact form component to be used in the contact section of the landing page
  return (
      <div className={styles.contact_container}>
        <div className={styles.contact_border}></div>
          <form action="https://formsubmit.co/admin@triumphrealtyllc.com" method="POST" className={styles.contact_form}>

              {/* 3 input fields below make sure no bots/scammers can use the page*/}
              <input type="hidden" name="_next" value="https://www.triumphrealtyllc.com"/>
              <input type="hidden" name="_captcha" value="true"/>
              <input type="text" name="_honey" style={{display: "none"}}/>

              {/* the rest of the form*/}
              <p className={styles.contact_label}>Name</p>
              <input type="text" name="name" required/>
              <p className={styles.contact_label}>Email</p>
              <input type="email" name="email" required/>
              <p className={styles.contact_label}>Subject</p>
              <input type="subject" name="subject" required/>
              <p className={styles.contact_label}>Message</p>
              <textarea name="message" rows="5" required></textarea>
              <button type="submit">Send Message</button>
          </form>
      </div>
  );
};

export default ContactForm;

