import styles from './WhoWeAre.module.css'

const WhoWeAre = () => {
    return (

        <div className={styles.who_we_are_container}>
            <div className={styles.text_container}>
                <h1 className={styles.text_header}>Who We Are</h1>
                <p className={styles.text_para}>Triumph Realty, a distinguished Real Estate Brokerage, sets itself apart in the industry with a commitment to excellence and a client-centric approach. With a team of seasoned and dedicated real estate professionals, Triumph Realty excels in providing comprehensive brokerage services. Whether buying, selling, or investing in real estate, clients benefit from the brokerage's extensive market knowledge, strategic insights, and personalized guidance. Triumph Realty's success lies in its ability to navigate the complexities of the real estate market, leveraging cutting-edge technology and a customer-first philosophy. As a trusted partner, Triumph Realty empowers clients to achieve their real estate goals, making each transaction a triumph in itself.</p>
            </div>

            <div className={styles.pic_container}>
                <div className={styles.bordered_pic1}>
                    <div className={styles.pic_border}></div>
                    <img src="assets/building3.png" alt='Who we are section, Skyscraper 1' />
                </div>
                <div className={styles.bordered_pic2}>
                    <div className={styles.pic_border}></div>
                    <img src="assets/interior.png" alt='Who we are Skyscraper picture'/>
                </div>
            </div>
        </div>
    )
}

export default WhoWeAre;