import styles from './NavBar.module.css'
const NavBar = () => {

    return (
        <nav className={styles.nav_bar}>
            <ul className={styles.ul}>
                <li className={styles.li}><a href='#home'>Home</a></li>
                <li className={styles.li}><a href='#about'>About</a></li>
                <img className={styles.logo} src='/assets/monogram.png'
                                               alt='Triumph Realty Logo'/>
                <li className={styles.li}><a href='#services'>Services</a></li>
                <li className={styles.li}><a href='#contact'>Contact</a></li>
            </ul>
        </nav>
    )
}

export default NavBar;