import styles from './Footer.module.css'

const Footer = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footer_text}>Copyright © {new Date().getFullYear()} Triumph Realty - AZARK - All
                Rights Reserved.
            </div>
            <div className={styles.footer_social}>
                <a href='https://www.facebook.com/people/Azark-Inc/61556755064575/' target={"_blank"}><img src='assets/footer-facebook.png' alt='Facebook icon'/></a>
                <a href='https://instagram.com/azark.nyc' target={"_blank"}><img src='assets/footer-insta.png' alt='AAPC icon'/></a>
                <a href='https://www.linkedin.com/company/azark-development' target={"_blank"}><img src='assets/footer-linkedin.png' alt='Arkco icon'/></a>
            </div>
            <div className={styles.footer_icons}>
                <div>
                    <a href='https://bvs.nyc' target={"_blank"}><img src='assets/footerIcon4.png' alt='BVS icon'/></a>
                    <a href='https://aapc.nyc' target={"_blank"}><img src='assets/footerIcon2.png' alt='AAPC icon'/></a>
                    <a href='https://arkco.nyc' target={"_blank"}><img src='assets/footerIcon3.png' alt='Arkco icon'/></a>
                    <a href='https://www.azark.com' target={"_blank"}><img src='assets/footerIcon1.png' alt='Azark icon'/></a>
                    <a href='https://az-title.com/' target={"_blank"}><img src='assets/footerIcon5.png' alt='AZ-Title icon'/></a>
                    <a href='https://www.violerts.com' target={"_blank"}><img src='assets/footerIcon7.png' alt='Violerts icon'/></a>
                    <a href='https://vps.nyc' target={"_blank"}><img src='assets/footerIcon6.png' alt='VPS icon'/></a>
                </div>
            </div>
        </div>
    );
};

export default Footer;