import styles from './ContactUs.module.css'
import ContactForm from "../contactForm/ContactForm";

const ContactUs = () => {
    return (
        <div className={styles.contact_us_container}>

            {/* left side of screen, top in mobile*/}
            <div className={styles.contact_left}>
                <div className={styles.contact_pic_container}>
                    <div className={styles.contact_pic_border}></div>
                    <img className={styles.contact_pic} src='assets/pic2.png' alt="Cubic white building"></img>
                </div>
                <div className={styles.contact_details_container}>
                    <a className={styles.contact_detail_line} href={"mailto:admin@triumphrealtyllc.com"}>
                        <img src='assets/email.png' alt='Email icon'/>
                        <p>admin@triumphrealtyllc.com</p>
                    </a>
                    <a className={styles.contact_detail_line} href={"tel:2125479060"}>
                        <img src='assets/phone.png' alt='Phone icon'/>
                        <p>(212)547-9060</p>
                    </a>
                    <a className={styles.contact_detail_line} href={'https://maps.app.goo.gl/3S2kgXWk48vREADu9'}
                       target={"_blank"}>
                        <img src='assets/location.png' alt='Location icon'/>
                        <p>130 West 37th Street New York, NY 10018</p>
                    </a>
                </div>
            </div>

            {/* right side of screen, bottom in mobile*/}
            <div className={styles.contact_right}>
                <h1 className={styles.contact_header}>Contact Us</h1>
                <ContactForm/>
            </div>
        </div>
    )
}

export default ContactUs;